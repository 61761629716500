import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Alert, Box, styled, Typography, SxProps, Divider, alpha, lighten } from '@mui/material';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import classes from './GroupScheduling.module.css';
import { DateTime } from 'luxon';
import { MEETING_STATUS_LABELS } from '../../constants';
import {
  CabButton, CabControlLabel, CabDropdown, CabIcon, CabSwitch, CabToggleChip, CabTooltip
} from '@CabComponents';
import colors from '../../colors';
import { MeetingPollPriority, ParticipantsWithSelections, SelectedSlot } from '../../store';
import { useElementWidth } from '../../utils/hooks';
import CabSpinner from '@CabComponents/CabSpinner';
import { 
  IoEyeOffOutline, IoEyeOutline, IoEllipse, IoTimeOutline, IoPeopleOutline, IoChevronBack, IoCheckmark, 
  IoChevronForward, IoCalendarClearOutline, IoTrashOutline, IoWarningOutline } from 'react-icons/io5';
import { IconType } from 'react-icons';


interface HeaderProps {
  creatorName: string;
  creatorEmail: string;
  name: string;
  description: string;
  durationText: string;
  expectedParticipants: number;
  participantLength: number;
  onUpdateStatus?: (status: number) => Promise<void>;
}

export const Header = ({
  creatorName, creatorEmail, name, description, durationText, expectedParticipants, participantLength,
  onUpdateStatus,
}: HeaderProps): ReactElement => {
  const [showDescription, setShowDescription] = useState(false);

  return (
    <Box display="flex" width="100%" flexDirection="row" justifyContent="space-between">
      <Box display="flex" gap={2} flexDirection="column">
        <Box>
          <Typography variant="h1">{name}</Typography>
        </Box>
        <Box>
          {description && (
            <>
              <CabButton buttonType='tertiary' color='accent' 
                onClick={() => setShowDescription(!showDescription)}
                icon={<CabIcon Icon={showDescription ? IoEyeOffOutline : IoEyeOutline}/>} sx={{width: '178px'}}>
                {showDescription ? 'Hide Description' : 'See Description'}
              </CabButton>
              {showDescription && (
                <Typography whiteSpace='pre-wrap' color={colors.black600}>{description}</Typography>
              )}
            </>
          )}
        </Box>
        {creatorName && (
          <Box display="flex" maxWidth={'630px'} flexWrap='wrap' width={'80%'} gap={1.5}>
            <Box display="flex" gap={0.5}>
              <Typography color={colors.black600} whiteSpace="nowrap">Created by</Typography>
              <Typography fontWeight={700} color={colors.black600} whiteSpace="nowrap">{creatorName}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap={0.5}>
              <CabIcon Icon={IoEllipse} sx={{ fontSize: 6, color: colors.black600 }} />
              <Typography color={colors.black600}>{creatorEmail}</Typography>
            </Box>
          </Box>
        )}
        <Box display="flex" gap={1} >
          <CabToggleChip
            title={durationText}
            label={durationText}
            icon={<CabIcon Icon={IoTimeOutline} sx={{ fontSize: 20, paddingBottom: '2px' }} />}
            sx={{ backgroundColor: colors.purpleLight, borderColor: colors.purpleLight, height: 30, fontWeight: 500, }}
          />
          <CabToggleChip
            title={`${participantLength}/${expectedParticipants} Responded`}
            label={`${participantLength}/${expectedParticipants} Responded`}
            icon={<CabIcon Icon={IoPeopleOutline} sx={{ fontSize: 20, paddingBottom: '2px' }} />}
            sx={{ backgroundColor: colors.purpleLight, borderColor: colors.purpleLight, height: 30, fontWeight: 500 }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export interface StatusDropdownProps {
  status: number;
  onUpdateStatus: (status: number) => Promise<void>;
  onOpen?: () => void;
  onClose?: () => void;
  sx?: SxProps;
}

export const StatusDropdown = ({ status, onUpdateStatus, onOpen, onClose, sx }: StatusDropdownProps) => {
  const [statusLoading, setStatusLoading] = useState(false);

  return (
    <Box display='flex' alignItems='center' gap={.5}>
      <CabDropdown
        value={Object.keys(MEETING_STATUS_LABELS).find(key => Number(key) === status)} 
        placeholder="Select Status"
        onOpen={onOpen}
        onClose={onClose}
        options={Object.keys(MEETING_STATUS_LABELS).map((statusId) => ({
          value: statusId,
          label: MEETING_STATUS_LABELS[Number(statusId)],
          // onClick: e => e.stopPropagation(),
        }))}
        onChange={async (e) => {
          setStatusLoading(true);
          await onUpdateStatus(Number(e.target.value));
          setStatusLoading(false);
        }}
        sx={{ backgroundColor: colors.black100, ...sx }}
        // inputProps={{className: classes.statusSelectInput}}
      />
      {statusLoading && <CabSpinner scale={1} />}
    </Box>
  );
};


export type Slot = { start: DateTime, end: DateTime };

export interface PollTableProps {
  votable: boolean;
  name: string;
  participants: ParticipantsWithSelections;
  timezoneConvertedSlots: (Slot & { index: number; converted: Slot })[];
  onCheckSlot: (email: string, index: number, priority: MeetingPollPriority | null) => void;
  onConvertToMeeting?: (start: DateTime, end: DateTime) => void;
  onRemoveSlot?: (index: number) => void;
  slotIndexesToRemove?: Set<number>;
  startTime?: string|null;
  selectedSlots?: { [email: string]: Map<number, SelectedSlot | null> };
  highlightTopPicks?: boolean;
  inAdmin?: boolean;
  // returningUserEmailHashes?: {[hash: string]: string};
  activeParticipants?: { email: string; name: string }[];
  loading?: boolean;
  showEmailsOverride?: boolean;
  onShowEmailsOverride?: (show: boolean) => void;
  calendarOpen?: boolean;
  calendarDate?: DateTime;
  onEndReached?: (value: boolean) => void;
  enhancePollTableCursors?: boolean;
  onDateChange?: (date: DateTime) => void;
  sx?: SxProps;
  inProgress?: boolean;
}

const ParticipantName = styled(Typography, { label: "ParticipantName" })(
  {
    display: 'table-cell',
    minHeight: '70px',
    width: '100%',
    maxWidth: "120px",
    color: colors.black700,
    fontWeight: 500,
    wordSpacing: '2px',
    textAlign: 'center',
    verticalAlign: 'middle',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    alignItems: 'center',
  }
);

interface PollTableLegendProps {
  hidePopular?: boolean;
}

const goldColor = '#FFB800';

export const PollTableLegend = ({hidePopular}: PollTableLegendProps) => (
  <Box display="flex" justifyContent="space-between">
    <Box />
    <Box display="flex" flexDirection="row" gap={3}>
      <Box display="flex" flexDirection="row" gap={1} alignItems="center">
        <Box sx={{ backgroundColor: colors.navyPrimary }} minWidth={22} width={22} height={22} borderRadius={2} />
        <Typography>Available</Typography>
      </Box>
      <Box display="flex" flexDirection="row" gap={1} alignItems="center">
        <Box sx={{ borderStyle: 'solid', borderWidth: 2, borderColor: colors.navyPrimary }}
          minWidth={22} width={22} height={22} borderRadius={2}
        />
        <Typography>Potentially Available</Typography>
      </Box>
      {!hidePopular &&
        <Box display="flex" flexDirection="row" gap={1} alignItems="center">
          <Box sx={{ borderStyle: 'solid', borderWidth: 2, borderColor: goldColor }}
            minWidth={22} width={22} height={22} borderRadius={2}
          />
          <Typography>Popular Times</Typography>
        </Box>
      }
    </Box>
  </Box>
);

interface PollTableColumnProps {
  onColumnClick?: () => void;
  columnCursor?: string;
  header1: ReactElement | null;
  header2: ReactElement | null;
  rows: { key: string|number; element: ReactElement | null }[];
  sx?: SxProps;
}

const PollTableColumn = ({
  onColumnClick, columnCursor, header1, header2, rows, sx
}: PollTableColumnProps) => (
  <Box
    className={classes.timeSlotTableSlotColumn}
    onClick={onColumnClick}
    sx={{ cursor: columnCursor, overflow: 'visible', boxSizing: "border-box", ...sx }}
  >
    <Box height="40px" marginBottom="10px" whiteSpace="nowrap">
      {header1}
    </Box>

    <Box>
      {header2}
    </Box>
    {rows.map(({ key, element }) => {
      return (
        <Box
          key={key}
          className={classes.timeSlotTableSmallCell}
          sx={{ borderStyle: 'solid', borderWidth: 1, borderColor: colors.black100 }}
        >
          {element}
        </Box>
      );
    })}
  </Box>
);

const StyledToolTippedIcon = ({title, iconSx, Icon}: {title: string, iconSx: SxProps, Icon: IconType}) => {
  return <CabTooltip
    title={title}
    placement="right"
    wrapWithSpan
    sx={{ position: 'absolute', right: 5, top: -12 }}
  >
    <CabIcon Icon={Icon} sx={iconSx} />
  </CabTooltip>;
};

export const PollTable = ({
  votable, name, participants, timezoneConvertedSlots: convertedSlots, onCheckSlot: handleCheckSlot,
  onConvertToMeeting, startTime, selectedSlots, highlightTopPicks, inProgress,
  inAdmin, activeParticipants = [{ email: '', name: '' }], loading, showEmailsOverride, onShowEmailsOverride,
  calendarOpen, calendarDate, onEndReached, enhancePollTableCursors = false, onDateChange, sx, onRemoveSlot,
  slotIndexesToRemove
}: PollTableProps): ReactElement => {
  const [showEmails, setShowEmails] = useState(showEmailsOverride || false);
  const containerRef = useRef<Element | null>(null);
  const [swiper, setSwiper] = useState<SwiperRef['swiper'] | null>(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  useEffect(() => {
    if (showEmailsOverride != null && showEmails !== showEmailsOverride) {
      setShowEmails(showEmailsOverride);
    }
    // only trigger if the override changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEmailsOverride]);

  const sortedSlots = useMemo(() => (
    convertedSlots.sort((a, b) => a.start.toMillis() - b.start.toMillis())
  ), [convertedSlots]);

  const slots = useMemo(() => (
    sortedSlots.filter(slot => !slotIndexesToRemove?.has(slot.index))
  ), [sortedSlots, slotIndexesToRemove]);

  const width = useElementWidth(containerRef, 1000, 25);

  let slidesPerView = Math.max(Math.floor(width / 132), 2);
  let slidesPerGroup = slidesPerView > 1 ? slidesPerView - 1 : slidesPerView;
  slidesPerView = Math.min(slidesPerView, slots.length || 1);
  slidesPerGroup = Math.min(slidesPerGroup, slots.length || 1);

  const [backDisabled, setBackDisabled] = useState(true);
  const [forwardDisabled, setForwardDisabled] = useState(false);

  const votableSlotsDict: {[key: number]: boolean} = {};
  convertedSlots.forEach(slot => {
    votableSlotsDict[Number(slot.start.toMillis())] = true;
  });

  useEffect(() => {
    if (onEndReached && forwardDisabled) {
      onEndReached(true);  
    }
  }, [forwardDisabled, onEndReached]);

  useEffect(() => {
    setBackDisabled(activeSlideIndex === 0);
    setForwardDisabled(activeSlideIndex + slidesPerView >= slots.length);
  }, [slidesPerView, slots.length, activeSlideIndex]);

  const slotFrequency = participants.flatMap(participantsItr => participantsItr.selectedSlots)
    .map(slot => ({
      [slot.start.toMillis()]: { [slot.priority || MeetingPollPriority.AVAILABLE]: 1 }
    }))
    // filter out slots that are not votable
    .filter(cur => votableSlotsDict[Number(Object.keys(cur)[0])])
    .reduce((prevSlot, slot) => {
      const slotKey = Number(Object.keys(slot)[0]);

      return {
        ...prevSlot,
        [slotKey]: {
          [MeetingPollPriority.AVAILABLE]: (
            (prevSlot[slotKey]?.[MeetingPollPriority.AVAILABLE] || 0)
              + (Object.values(slot)[0][MeetingPollPriority.AVAILABLE] || 0)
          ),
          [MeetingPollPriority.POTENTIAL]: (
            (prevSlot[slotKey]?.[MeetingPollPriority.POTENTIAL] || 0)
              + (Object.values(slot)[0][MeetingPollPriority.POTENTIAL] || 0)
          ),
        }
      };
    }, {});

  let max = 0;
  Object.values(slotFrequency).forEach(freq => {
    const totalFreq = freq[MeetingPollPriority.AVAILABLE] + freq[MeetingPollPriority.POTENTIAL];
    if (totalFreq > max) max = totalFreq;
  });
  const topSlots = Object.keys(slotFrequency).map(timeslot => Number(timeslot)).filter((timeslot) => {
    const totalFreq = slotFrequency[timeslot][MeetingPollPriority.AVAILABLE]
      + slotFrequency[timeslot][MeetingPollPriority.POTENTIAL];
    return totalFreq > 0 && totalFreq === max;
  });

  //const participantString = participants.length !== 1 ? 'participants' : 'participant';
  // const filterParticipants = participants.filter(
  //   participant => !Object.keys(returningUserEmailHashes).includes(participant.emailHash) || !votable
  // );
  const filterParticipants = participants;
  // const activeParticipants = participants.filter(
  //   participant => Object.keys(returningUserEmailHashes).includes(participant.emailHash)
  // );

  // const multiYear = convertedSlots.length <= 0
  //   ? false
  //   : sortedSlots[0].converted.start.year !== sortedSlots[sortedSlots.length - 1].converted.start.year;

  const handleChangeDate = useCallback((date: DateTime) => {
    if (!swiper) return;

    const slotIndex = slots.findIndex(s => (
      s.converted.start.startOf('day').toMillis() === date.startOf('day').toMillis()
    ));
    if (slotIndex > -1) {
      swiper.slideTo(slotIndex, 200);
    }
  }, [swiper, slots]);

  const handlePrev = useCallback(() => {
    if (!swiper) return;
    if (!calendarOpen || !calendarDate || !onDateChange) {
      swiper.slidePrev();
      return;
    }

    const currentlyVisibleSlots = slots.filter((slot) => (
      slot.index >= swiper.activeIndex && (slot.index < swiper.activeIndex + slidesPerView)
    ));

    const prevDateInView = currentlyVisibleSlots.reverse().find(slot => (
      slot.converted.start.startOf('day').toMillis() < calendarDate.startOf('day').toMillis()
    ));

    if (prevDateInView) {
      onDateChange(prevDateInView.converted.start.startOf('day'));
    } else {
      swiper.slidePrev();
    }
  }, [swiper, slots, slidesPerView, calendarDate, onDateChange, calendarOpen]);

  const handleNext = useCallback(() => {
    if (!swiper) return;
    if (!calendarOpen || !calendarDate || !onDateChange) {
      swiper.slideNext();
      return;
    }

    const currentlyVisibleSlots = slots.filter((slot) => (
      slot.index >= swiper.activeIndex && (slot.index < swiper.activeIndex + slidesPerView)
    ));

    const nextDateInView = currentlyVisibleSlots.find(slot => (
      slot.converted.start.startOf('day').toMillis() > calendarDate.startOf('day').toMillis()
    ));

    if (nextDateInView) {
      onDateChange(nextDateInView.converted.start.startOf('day'));
    } else {
      swiper.slideNext();
    }
  }, [swiper, slots, slidesPerView, calendarDate, onDateChange, calendarOpen]);

  // sync active slot to current calendar date
  useEffect(() => {
    if (!swiper || !calendarOpen) return;

    const activeSlotDay = slots[swiper.activeIndex].converted.start.startOf('day');

    if (calendarDate && activeSlotDay.toMillis() !== calendarDate.toMillis()) {
      handleChangeDate(calendarDate);
    }
  }, [calendarOpen, calendarDate, handleChangeDate, slots, swiper]);

  const timeSlotTableParticipantClass = showEmails 
    ? classes.timeSlotTableParticipantEmail 
    : classes.timeSlotTableParticipantName;

  const timeSlotClass = onConvertToMeeting && inProgress 
    ? [classes.timeSlotTableCell, classes.timeSlotWithButton].join(" ")
    : classes.timeSlotTableCell;

  return (
    <>
      {loading || convertedSlots.length !== 0 ? (
        <>
          <Box display="flex" flexDirection="row"
            sx={{
              backgroundColor: colors.white900,
              borderStyle: "solid", borderWidth: 2, borderColor: colors.black100, borderRadius: 3,
              ...sx,
            }}
          >
            <Box
              sx={{
                display: { md: 'flex', xs: 'none' }, flexDirection: 'column', maxWidth: 220, marginTop: '12px',
                borderStyle: 'solid', borderWidth: 0, borderRightWidth: 1, borderColor: colors.black100,
              }}
            >
              {/* <Box height={multiYear ? 40 : 0} /> */}
              <div className={classes.timeSlotTableEmptyCellDate} />
              <Box paddingRight={1} display={inAdmin ? 'auto' : 'table'} className={timeSlotClass}>
                {inAdmin &&
                  <div className={classes.timeSlotTableCell}>
                    <CabControlLabel
                      label="Show Emails"
                      labelPlacement='end'
                      sx={{ paddingRight: 2, marginLeft: 1 }}
                      control={<CabSwitch checked={showEmails} onChange={() => {
                        if (onShowEmailsOverride) {
                          onShowEmailsOverride(!showEmails);
                        } else {
                          setShowEmails(!showEmails);
                        }
                      }} />}
                    />
                  </div>
                }
                {activeParticipants.length > 0 && (
                  <Box display="flex" flexDirection="column">
                    {activeParticipants.map((participant, idx) => (
                      <Box
                        key={participant.email}
                        className={timeSlotTableParticipantClass}
                        height={idx + 1 === activeParticipants.length ? 70 : 71}
                        maxWidth={80}
                        position="relative"
                        display={'table'}
                      >
                        <ParticipantName
                          key={participant.email}
                          sx={{overflowWrap: 'normal', color: colors.black900, paddingLeft: 1 }}
                        >
                          {!votable ? '' : participant.name || participant.email}
                        </ParticipantName>
                      </Box>
                    ))}
                    {activeParticipants.length > 0 && !inAdmin && <Divider sx={{ marginLeft: 2, marginRight: 1 }} />}
                  </Box>
                )}
              </Box>
              {filterParticipants.map((participant, idx) => (
                <Box
                  key={participant.emailHash + idx}
                  className={timeSlotTableParticipantClass}
                  height={71}
                  maxWidth={80}
                  position="relative"
                  display={'table'}
                >
                  <ParticipantName sx={{fontStyle: participant.required === false ? 'italic' : 'unset'}}>
                    {(
                      showEmails ?
                        participant.required === false ?
                          `${participant.email}*` : participant.email 
                        : participant.required === false ?
                          (participant.name ?  `${participant.name}*` : `${participant.email}*`)
                          : (participant.name || participant.email)
                    ) || `Attendee ${idx + 1}`}
                  </ParticipantName>
                  {inAdmin && !participant.first_response_date && (
                    <StyledToolTippedIcon
                      title="There are no responses for this attendee"
                      iconSx={{ marginTop: 2 }}
                      Icon={IoTimeOutline}
                    />
                  )}
                  {(inAdmin && participant.first_response_date && participant.selectedSlots.length === 0) && (
                    <StyledToolTippedIcon
                      title="No times work for this participant"
                      iconSx={{ marginTop: 2 }}
                      Icon={IoWarningOutline}
                    />
                  )}
                </Box>
              ))}
            </Box>

            <Box className={classes.timeSlotTableMain} paddingTop={2} ref={containerRef}>
              <PollTableColumn
                header1={<Box />}
                header2={
                  <>
                    {activeParticipants.map((participant, idx) => (
                      <Box key={participant.email} marginRight={1} marginLeft={1} 
                        height={onConvertToMeeting && inProgress ? 130 : 80}>
                        {idx === 0 && (
                          <CabButton
                            disabled={backDisabled}
                            icon={<CabIcon Icon={IoChevronBack} />}
                            sx={{
                              minWidth: 40, width: '80%', padding: 0, height: 'calc(100% - 6px)',
                              backgroundColor: colors.greenLight, color: colors.greenDark,
                              '& .MuiButton-startIcon': { margin: 0 },
                            }}
                            onClick={handlePrev}
                          />
                        )}
                      </Box>
                    ))}
                  </>
                }
                rows={filterParticipants.map((participant, idx) => ({
                  key: participant.emailHash + idx,
                  element: <Box />
                }))}
              />

              <Swiper
                onSwiper={setSwiper}
                slidesPerView={slidesPerView}
                slidesPerGroup={slidesPerGroup}
                modules={[Pagination, Navigation]}
                watchSlidesProgress
                allowTouchMove={false}
                style={{ minWidth: 150 }}
                resizeObserver
                onNavigationNext={() => console.log('nav next')}
                onActiveIndexChange={s => {
                  setActiveSlideIndex(s.activeIndex);

                  if (!onDateChange || !calendarDate) return;

                  const currentlyVisibleSlots = slots.filter((slot, i) => (
                    i >= s.activeIndex && (i < s.activeIndex + slidesPerView)
                  ));
                  const visibleDayMillis = currentlyVisibleSlots.map(slot => (
                    slot.converted.start.startOf('day').toMillis()
                  ));
                  const firstVisibleSlotDate = slots[s.activeIndex].converted.start.startOf('day');

                  // if (calendarDate.startOf('day').toMillis() !== firstVisibleSlotDate.toMillis()) {
                  if (!visibleDayMillis.includes(calendarDate.startOf('day').toMillis())) {
                    onDateChange(firstVisibleSlotDate);
                  }
                }}
              >
                {slots.map((slot, slotIdx, slotsItr) => {
                  // const year = slot.converted.start.year;
                  const date = slot.converted.start
                    .toLocaleString({ weekday: 'short', month: 'long', day: 'numeric' });
                  const shortDate = slot.converted.start
                    .toLocaleString({ month: 'short', day: 'numeric' });

                  const isSelectedDate = calendarDate ? slot.start.hasSame(calendarDate, 'day') : false;

                  return (
                    <SwiperSlide
                      key={`${slot.converted.start.toISO()}-${slot.converted.end.toISO()}`}
                      style={{ maxWidth: 200, overflow: 'visible', zIndex: -slotIdx }}
                    >
                      {({ isVisible, isActive }) => {
                        const prevSlot = slotsItr[slotIdx - 1];
                        const nextSlot = slotsItr[slotIdx + 1];
                        const prevDate = prevSlot
                          ? prevSlot.converted.start.toLocaleString({ weekday: 'short', month: 'long', day: 'numeric' })
                          : null;
                        const renderDate = isVisible
                          && (!prevDate || prevDate !== date || isActive)
                          ? date : null;
                        const isLastSlotForDate = date !== nextSlot?.converted.start
                          .toLocaleString({ weekday: 'short', month: 'long', day: 'numeric' });
                        const isLastVisibleSlot = isVisible && slotIdx === (activeSlideIndex + slidesPerView - 1);

                        return (
                          <PollTableColumn
                            // columnCursor="pointer"
                            header1={
                              <Box
                                sx={{
                                  ...(isLastSlotForDate ? {
                                    borderTopRightRadius: 5, borderBottomRightRadius: 5, marginRight: 0.5,
                                  } : {}),
                                  ...(renderDate ? {
                                    borderTopLeftRadius: 5, borderBottomLeftRadius: 5, marginLeft: 0.5,
                                  } : {}),
                                  position: 'relative', 
                                  backgroundColor: isSelectedDate || !calendarOpen 
                                    ? colors.purpleLight : colors.black100, 
                                  color: isSelectedDate || !calendarOpen
                                    ? colors.black700 : colors.black400,
                                  height: '100%', paddingLeft: 1, paddingTop: 1,
                                  overflow: renderDate ? 'visible' : 'hidden', zIndex: renderDate ? 1 : -1
                                }}
                              >
                                {renderDate && (
                                  <CabTooltip
                                    title={<Box sx={{ fontSize: 14 }}>{date}</Box>}
                                    placement="top"
                                  >
                                    <Box fontWeight={500}>
                                      {(isLastSlotForDate || isLastVisibleSlot
                                        || width <= 400 || convertedSlots.length <= 2)
                                        ? shortDate
                                        : date}
                                    </Box>
                                  </CabTooltip>
                                )}
                              </Box>
                            }
                            header2={
                              <>
                                {activeParticipants.map((participant, idx) => (
                                  <TimeSlotOption
                                    key={participant.email}
                                    slot={slot}
                                    isDateSelected={isSelectedDate || !calendarOpen}
                                    onConvertToMeeting={onConvertToMeeting}
                                    onRemoveSlot={onRemoveSlot}
                                    startTime={startTime}
                                    inProgress={inProgress}
                                    isCheckedAvailable={
                                      selectedSlots?.[participant.email]?.get(slot.index)?.priority 
                                      === MeetingPollPriority.AVAILABLE
                                    }
                                    isCheckedPotential={
                                      selectedSlots?.[participant.email]?.get(slot.index)?.priority 
                                      === MeetingPollPriority.POTENTIAL
                                    }
                                    isPrimaryHighlighted={!!(highlightTopPicks && topSlots
                                      .includes(slot.start.toMillis()))}
                                    isMarkedForRemoval={slotIndexesToRemove?.has(slot.index)}
                                    onClick={votable
                                      ? () => {
                                        const priority = selectedSlots?.[participant.email]?.get(slot.index)?.priority;
                                        const prioritySubmit = priority !== undefined ? priority : null; 
                                        return handleCheckSlot(
                                          participant.email,
                                          slot.index,
                                          prioritySubmit,
                                        );
                                      }
                                      : undefined
                                    }
                                    totalAvailableVotes={
                                      slotFrequency[slot.converted.start.toMillis()]?.[MeetingPollPriority.AVAILABLE]
                                      || 0
                                    }
                                    totalPotentialVotes={
                                      slotFrequency[slot.converted.start.toMillis()]?.[MeetingPollPriority.POTENTIAL]
                                      || 0
                                    }
                                  />
                                ))}
                              </>
                            }
                            rows={filterParticipants.map((participant, idx) => {
                              const chosenSlot = Array.from(participant.selectedSlots.values())
                                .find(s => s?.start.equals(slot.start) && s?.end.equals(slot.end));

                              return {
                                key: participant.emailHash + idx,
                                element: chosenSlot ? (
                                  <Box width={'100%'} height={'100%'} padding={1}
                                    sx={{
                                      backgroundColor: isSelectedDate || !calendarOpen 
                                        ? 'unset' : alpha(colors.black900, 0.05),
                                    }}
                                  > 
                                    <CabTooltip
                                      title={participant.name || `Attendee ${idx + 1}`}
                                      placement="top"
                                      wrapWithSpan
                                    >
                                    
                                      <Box sx={{
                                        backgroundColor: chosenSlot.priority === MeetingPollPriority.AVAILABLE
                                          ? isSelectedDate || !calendarOpen 
                                            ? colors.navyPrimary : lighten(colors.navyPrimary, .3)
                                          : isSelectedDate || !calendarOpen ? colors.white900 : 'transparent',
                                        border: isSelectedDate || !calendarOpen ? 
                                          `2px solid ${colors.navyPrimary}` 
                                          : `2px solid ${lighten(colors.navyPrimary, .3)}`,
                                        height: 54,
                                        borderRadius: 1,
                                        display: "flex"
                                      }}>
                                        <CabIcon Icon={IoCheckmark} alt={participant.name} 
                                          sx={{
                                            fontSize: 20,
                                            color: chosenSlot.priority === MeetingPollPriority.AVAILABLE
                                              ? colors.white900
                                              : colors.navyPrimary,
                                            pointerEvents: 'none',
                                            margin: 'auto',
                                          }}
                                        />
                                      </Box>
                                    
                                    </CabTooltip>
                                  </Box>
                                ) : (
                                  <Box width={'100%'} height={'100%'} 
                                    sx={{
                                      backgroundColor: isSelectedDate || !calendarOpen ? 'unset' : colors.black900, 
                                      opacity: 0.05
                                    }} 
                                  />)
                              };
                            })}
                          />
                        );
                      }}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              
              <PollTableColumn
                header1={<Box />}
                header2={
                  <>
                    {activeParticipants.map((participant, idx) => (
                      <Box key={participant.email} marginRight={1} marginLeft={1} 
                        height={onConvertToMeeting && inProgress ? 130 : 80}>
                        {idx === 0 && (
                          <CabButton
                            disabled={forwardDisabled}
                            icon={<CabIcon Icon={IoChevronForward} />}
                            sx={{
                              minWidth: 40, width: '80%', padding: 0, height: 'calc(100% - 6px)',
                              backgroundColor: enhancePollTableCursors ? colors.greenDark : colors.greenLight,
                              color: enhancePollTableCursors ? colors.greenLight : colors.greenDark,
                              '& .MuiButton-startIcon': { margin: 0 },
                            }}
                            onClick={handleNext}
                          />
                        )}
                      </Box>
                    ))}
                  </>
                }
                rows={filterParticipants.map((participant, idx) => ({
                  key: participant.emailHash + idx,
                  element: <Box />
                }))}
              />

            </Box>
          </Box>
        </>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12} sm={9} md={7}>
              <Alert severity="warning">
                <span>No participants have selected available times yet</span>
              </Alert>
            </Grid>
          </Grid>
          <Box>
            {inAdmin &&
            <div className={classes.noRespondentsCheck}>
              <CabControlLabel
                label="Show Emails"
                labelPlacement='end'
                control={<CabSwitch checked={showEmails} onChange={() => {
                  if (onShowEmailsOverride) {
                    onShowEmailsOverride(!showEmails);
                  } else {
                    setShowEmails(!showEmails);
                  }
                }} />}
              />
            </div>
            }
            {!votable ? '' : name}
            {filterParticipants.map((participant, idx) => (
              <Box
                key={participant.id}
                className={[classes.timeSlotTableSmallCell, classes.timeSlotTableParticipantName].join(" ")}
                sx={inAdmin ? {
                  display: 'flex', flexDirection: 'row', justifyContent: 'space-between', position: 'relative',
                } : {}}
              >
                <Typography color={colors.black700} display={'flex'} justifyContent='center' 
                  alignItems={'center'} height='71px'
                >
                  {showEmails ? participant.email : participant.name || participant.email || `Attendee ${idx + 1}`}
                </Typography>
                {inAdmin && !participant.first_response_date && (
                  <StyledToolTippedIcon
                    title="There are no responses for this attendee"
                    iconSx={{ marginTop: 3.5, marginRight: 2 }}
                    Icon={IoTimeOutline}
                  />
                )}
                {(inAdmin && participant.first_response_date && participant.selectedSlots.length === 0) && (
                  <StyledToolTippedIcon
                    title="No times work for this participant"
                    iconSx={{ marginTop: 3.5, marginRight: 2 }}
                    Icon={IoWarningOutline}
                  />
                )}
              </Box>
            ))}
          </Box>
        </>
      )}
    </>
  );
};

interface TimeSlotOptionProps {
  slot: (Slot & { index: number; converted: Slot });
  onConvertToMeeting?: (start: DateTime, end: DateTime) => void;
  onRemoveSlot?: (index: number) => void;
  startTime?: string|null;
  isCheckedAvailable: boolean;
  isCheckedPotential: boolean;
  isPrimaryHighlighted: boolean;
  onClick?: () => void;
  isDateSelected?: boolean;
  isMarkedForRemoval?: boolean;
  totalAvailableVotes?: number;
  totalPotentialVotes?: number;
  inProgress?: boolean;
}

const TimeSlotOption = ({
  slot, onConvertToMeeting, startTime, isCheckedAvailable, isCheckedPotential, isPrimaryHighlighted, onClick,
  isDateSelected, onRemoveSlot, isMarkedForRemoval, totalAvailableVotes, totalPotentialVotes, inProgress
}: TimeSlotOptionProps): ReactElement => {
  const [converting, setConverting] = useState(false);

  const handleConvertToMeeting = onConvertToMeeting ? (async() => {
    setConverting(true);
    await onConvertToMeeting(slot.start, slot.end);
    setConverting(false);
  }) : undefined;

  const timeSlotClass = handleConvertToMeeting && inProgress 
    ? [classes.timeSlotTableCell, classes.timeSlotWithButton].join(" ")
    : classes.timeSlotTableCell;
    
  return (
    <Box
      className={timeSlotClass}
      onClick={onClick}
      sx={{ cursor: onClick ? "pointer" : undefined }}
    >
      <Box sx={{
        display: 'flex',
        backgroundColor: colors.white900,
        borderColor: isDateSelected ? colors.black200 : colors.black100,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 1,
        paddingLeft: .25,
        paddingRight: .25,
        marginLeft: 0.5,
        minWidth: '82px',
        marginRight: 0.5,
        textAlign: "center",
        color: isDateSelected ? colors.black700 : colors.black400,
        ...(startTime && !inProgress && DateTime.fromISO(startTime).equals(slot.start) ? {
          backgroundColor: colors.navyPrimary,
          color: colors.white900,
        } : {}),
        ...(isPrimaryHighlighted ? {
          borderColor: goldColor,
        } : {}),
        ...(isCheckedAvailable ? {
          backgroundColor: colors.navyPrimary,
          borderColor: colors.navyPrimary,
          color: colors.white900,
        } : {}),
        ...(isCheckedPotential ? {
          backgroundColor: colors.white900,
          borderColor: colors.navyPrimary,
          color: colors.navyPrimary,
        } : {}),

      }} >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingTop={1}
          paddingBottom={1}
          width="100%"
          height={handleConvertToMeeting && inProgress ? 122 : 72}
          sx={{ backgroundColor: isMarkedForRemoval ? 'red' : undefined }}
        >
          <Box sx={{userSelect: 'none'}} fontWeight={500} fontSize={15} minWidth={70}>
            {`${slot.converted.start.toFormat('h:mma').toLowerCase()} -`}
          </Box>
          <Box sx={{userSelect: 'none'}} fontWeight={500} fontSize={15}>
            {slot.converted.end.toFormat('h:mma').toLowerCase()}
          </Box>
          {handleConvertToMeeting && inProgress && !onRemoveSlot && (
            <CabButton
              buttonType="text"
              color="secondary"
              onClick={handleConvertToMeeting}
              disabled={converting}
              icon={converting ? <CabSpinner scale={0.7} /> : <CabIcon size="small" Icon={IoCalendarClearOutline} />}
              sx={{ '& .MuiButton-startIcon': { margin: 0 }, marginTop: 0.5, minWidth: 30, width: 36,
                borderStyle: 'solid', borderColor: colors.black100, borderWidth: 2, borderRadius: 5,
              }}
            />
          )}
          {onRemoveSlot && (
            <CabButton
              buttonType="text"
              color="error"
              onClick={() => onRemoveSlot(slot.index)}
              icon={<CabIcon size="small" Icon={IoTrashOutline} />}
              sx={{ '& .MuiButton-startIcon': { margin: 0 }, marginTop: 0.5, minWidth: 30, width: 36,
                borderStyle: 'solid', borderColor: colors.black100, borderWidth: 2, borderRadius: 5,
              }}
            />
          )}
          {handleConvertToMeeting && totalAvailableVotes != null && totalPotentialVotes != null && (
            <CabTooltip
              title={[
                `${totalAvailableVotes ? `${totalAvailableVotes} available` : ''}`,
                `${totalPotentialVotes ? `${totalPotentialVotes} potentially available` : ''}`
              ].filter(t => !!t).join(', ')}
            >
              <Box display="flex" flexDirection="row" gap={1} fontSize={14} marginTop={1}>
                {totalAvailableVotes + totalPotentialVotes}
                {totalAvailableVotes + totalPotentialVotes > 1 ? ' votes' : ' vote'}
              </Box>
            </CabTooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
};
